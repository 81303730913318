import axios from "axios";
import { Notification } from "../models/notifications";

export const getNotifications = async (
    authUser: () => { tokenType: string; token: string },
    agent_country_id: number
):  Promise<Notification[]> => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/getNotification`,
        { agent_country_id },
        {
          headers: {
            Authorization: `${authUser().tokenType} ${authUser().token}`,
          },
        }
      );

      return response.data.data as Notification[];
    } catch (error) {
      return [];
    }
};