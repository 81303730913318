import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "../slices/toast-slice";
import { NON_CANCELABLE_STATUSES } from "../constants/constants";
import { useHttpClient } from "../services/useHttpClient";

export const useSendMoney = () => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formikRef = useRef();
    const httpClient = useHttpClient();
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const isCancelable = (status) => !NON_CANCELABLE_STATUSES.includes(status);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleCancelClick = (transaction) => {
        setSelectedTransaction(transaction);
        toggleModal();
    };
  
    const cancelOrRequestCancel = async (transactionId, reason, updateTransactionStatus) => {
      setLoading(true);
      try {
        const response = await httpClient.post("/cancelOrRequestCancel", {
          send_money_transaction_id: transactionId,
          agent_comment: reason,
        });
        if(response.data.status === 1){
          updateTransactionStatus();
          dispatch(showToast(["success", "NOTICE", t('TRANSACTION_CANCELLATION_REQUEST_SUCCESSFUL')]));
        }else {
          const message = response.data.message;
          dispatch(showToast(["danger", "NOTICE", t(message)]));

        }
      } catch (error) {
        dispatch(showToast(["danger", "NOTICE", t("FAILED_TO_REQUEST_TRANSACTION_CANCELLATION")]));
      } finally {
        setLoading(false);
        setTimeout(() => {
          toggleModal();
        }, 2000);
      }
    };
  
    return {
        handleCancelClick,
        cancelOrRequestCancel,
        t,
        toggleModal,
        setLoading,
        isCancelable,
        loading,
        showModal,
        formikRef,
        httpClient,
        selectedTransaction,
    };
};
  