import { getNotifications } from "../services/notifications.ts";

export interface Notification {
    id: number;
    title: string;
    message: string;
    images: string;
    icon: string;
    click_action: string;
    priority: "high" | "low";
    status: number;
    notification_type: "in_app_notification" | "push_notification";
    expiry_date: string;
    agent_country_id: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    notification_for: string;
    is_published: 0 | 1;
}

export const initialNotification : Notification = {
    id: 0,
    title: "",
    message: "",
    images: "",
    icon: "",
    click_action: "",
    priority: "low",
    status: 0,
    notification_type: "in_app_notification",
    expiry_date: "",
    agent_country_id: 0,
    updated_by: 0,
    created_at: "",
    updated_at: "",
    notification_for: "",
    is_published: 0

}
  
export class NotificationModel {
    private notifications: Notification[];

    constructor() {
        this.notifications = [];
    }

    async fetchNotifications(authUser: () => { tokenType: string; token: string }, agent_country_id: number): Promise<Notification[]> {
        const data: Notification[] = await getNotifications(authUser, agent_country_id);
        this.notifications = data;
        return data;
    }
    
    getLocalNotifications(): Notification[] {
        return this.notifications;
    }
}
  