import { Formik, Field, Form, ErrorMessage } from 'formik';
import { requestCancellationValidationSchema } from '../../../utils/validationSchemas';

export const RequestCancelOrRequestCancelDetails = ({ selectedTransaction, cancelOrRequestCancel, updateTransactionStatus, toggleModal, formikRef, t }) => {

  return (
    <div>
      <table className="mb-4">
        <tbody>
          <tr>
            <td>{t('PICKUP_CODE')} :&nbsp;&nbsp;&nbsp;</td>
            <td>{selectedTransaction.pickup_code}</td>
          </tr>
          <tr>
            <td>{t('AMOUNT')} :&nbsp;&nbsp;&nbsp;</td>
            <td>{selectedTransaction.sending_amount} {selectedTransaction.sending_currency}</td>
          </tr>
        </tbody>
      </table>
      <Formik
        innerRef={formikRef} 
        initialValues={{ reason: '' }}
        validationSchema={requestCancellationValidationSchema(t)}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { setSubmitting }) => {
          await cancelOrRequestCancel(selectedTransaction.id, values.reason, updateTransactionStatus);
          setSubmitting(false);
          toggleModal();
        }}
      >
        {() => (
          <Form>
            <div className="form-group mb-4">
              <label htmlFor="reason" className="mb-1">{t('REASON')}</label>
              <Field
                as="textarea"
                className="form-control form-control-sm w-100"
                id="reason"
                name="reason"
                rows="3"
                placeholder={t('REASON_FOR_CANCELLATION_REQUEST_PLACEHOLDER')}
              />
              <ErrorMessage name="reason" component="div" className="text-danger small mt-1" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const Toast = ({ type, message, title = "Notification", time = "Just now" }) => {
  return (
    <div className={`toast bg-${type}`} role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-header">
        <strong className="me-auto">{title}</strong>
        <small>{time}</small>
        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div className="toast-body">
        {message}
      </div>
    </div>
  );
};