import React from 'react';

const CustomModal = ({ 
  showModal, 
  title, 
  onClose, 
  onSubmit, 
  modalChildrenBody, 
  modalChildrenFooter, 
  submitButtonText, 
  t 
}) => {

  if (!showModal) return null;

  return (
    <div
      className='modal fade show'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      style={{ display: 'block' }}
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content custom-modal'>
          <div className='modal-header'>
            <h5 className='modal-title h5'>{title}</h5>
          </div>
          <div className='modal-body text-secondary pt-4'>
            {modalChildrenBody}
          </div>

          <div className='modal-footer'>
            {modalChildrenFooter || (
              <>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  onClick={onClose}
                >
                  {t('CLOSE')}
                </button>
                {onSubmit && (
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={onSubmit}
                  >
                    {submitButtonText}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
