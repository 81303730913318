import React, { useState } from "react";
import { Offcanvas, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { initialNotification, Notification } from "../../models/notifications.ts";
import { TFunction } from "i18next";

interface NotificationDrawerProps {
  notifications: Notification[];
  t: TFunction<"translation", undefined, "translation">;
}

const gradientBackgrounds = [
  "linear-gradient(to left top, #ebf9f8, #ecfaf9, #ecfbfa, #edfcfc, #eefdfd)",
  "linear-gradient(to left top, #f9f6eb, #faf7ec, #fbf8ec, #fcf9ed, #fdfbee)",
  "linear-gradient(to left top, #f9ebf3, #faecf4, #fbedf5, #fceef6, #fdeff7)",
  "linear-gradient(to left top, #ebf3f9, #ecf4fa, #edf5fb, #eef6fc, #eff7fd)",
];

const NotificationDrawer: React.FC<NotificationDrawerProps> = ({ notifications, t }) => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [notification, setNotification] = useState<Notification>(initialNotification);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNotificationClick = (notification: Notification) => {
    setNotification(notification);
    setModalShow(true);
  };

  return (
    <>
      <a className="nav-link" href="#" onClick={handleShow}>
        <i
          className="bx bx-bell"
          style={{ 
            verticalAlign: "middle", 
            fontSize: "20px", 
            color: "#333",
          }}
        />
        {notifications.length > 0 && (
          <span className="position-absolute h-badge translate-middle badge bg-danger bell-blink">
            {notifications.length}
          </span>
        )}
      </a>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notifications</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {notifications.length > 0 ? (
            <ul className="list-group">
              {notifications.map((notification, index) => {
                const backgroundStyle = gradientBackgrounds[index % gradientBackgrounds.length];

                return (
                  <li
                    key={notification.id}
                    className="list-group-item shadow-sm"
                    style={{
                      borderRadius: "8px",
                      marginBottom: "12px",
                      padding: "10px",
                      transition: "background 0.3s",
                      backgroundImage: backgroundStyle,
                      border: "1px solid #ddd",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNotificationClick(notification);
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <strong className="text-dark fs-6">{notification.title}</strong>
                      {notification.icon && (
                        <img
                          src={`${process.env.REACT_APP_BNB_BASE_URL}/${notification.icon}`}
                          alt="Icon"
                          className="rounded-circle"
                          style={{ width: "18px", height: "18px", objectFit: "cover" }}
                        />
                      )}
                    </div>

                    <div className="d-flex align-items-center">
                      {notification.images && (
                        <img
                          src={`${process.env.REACT_APP_BNB_BASE_URL}/${notification.images}`}
                          alt={notification.title}
                          className="me-2 rounded-2 border"
                          style={{ width: "50px", height: "50px", objectFit: "cover" }}
                        />
                      )}
                      <span className="text-muted small flex-grow-1">
                        {notification.message}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
              <i className="bx bx-bell-off text-secondary fs-1 mb-2" />
              <span className="text-muted">{t("NO_NOTIFICATIONS")}</span>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <Modal.Title>{notification.title || "Notification"}</Modal.Title>
          {notification.icon && (
            <img
              src={`${process.env.REACT_APP_BNB_BASE_URL}/${notification.icon}`}
              alt="Icon"
              className="rounded-circle"
              style={{ width: "32px", height: "32px", objectFit: "cover" }}
            />
          )}
        </Modal.Header>
        <Modal.Body className="text-center">
          {notification.images && (
            <img
              src={`${process.env.REACT_APP_BNB_BASE_URL}/${notification.images}`}
              alt="Aperçu"
              className="img-fluid rounded mb-3"
              style={{ maxHeight: "60vh", objectFit: "contain" }}
            />
          )}
          <p className="text-muted">{notification.message}</p>
          {notification.click_action && (
            <div className="mt-3">
              <a
                href={notification.click_action}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                {t("SEE_MORE")}
              </a>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationDrawer;
