import React, { useState, useEffect } from "react";
import user from "../assets/images/user.png";
import { useDispatch, useSelector } from "react-redux";
import { logout, set_LANGUAGE } from "../slices/auth-slice";
import { useSignOut } from "react-auth-kit";
import { NavLink, useNavigate } from "react-router-dom";
import { setGlobalAuthUser } from "../slices/auth-slice";
import { FcGlobe } from "react-icons/fc";
import { showToast } from '../slices/toast-slice';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import NotificationDrawer from "../components/layout/NotificationDrawer.tsx";

function Header({notifications}) {
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);

  const dispatch = useDispatch();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const togg = () => {
    document.querySelector(".sidebar").classList.toggle("close");
  };

  const onSignOut = () => {
    dispatch(logout());
    signOut();
    navigate("/login");
  };
  useEffect(() => {
      if(globalAuthUser.working_time_expired){
          onSignOut();
      }
  });

  return (
    <>
      <i className="bx bx-menu" onClick={togg} />

      <div style={{ width: "100%", textAlign: "right" }}>
        <ul
          style={{
            listStyle: "none",
            marginBottom: "0",
            paddingRight: "10px",
            paddingLeft: "0px",
          }}
        >
          {/* <li className="nav-item dropdown" style={{ display: "inline-block", padding: "0 0.8rem", lineHeight: "32px" }}>
                        <span style={{ fontSize: "4px"}}>ETS Mamadou Diallo et Freres</span>
                    </li> */}
          <span className="location_name">
            {" "}
            {globalAuthUser?.location?.name +
              " (" +
              globalAuthUser?.location?.code +
              ")"}{" "}
          </span>
          <li
            className="nav-item dropdown"
            style={{ display: "inline-block", padding: "0 0.8rem" }}
          >
            <a
              className="nav-link "
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {/* <div className="avatar avatar-md"> */}
              <div>
                {LANGUAGE === "en" ? (
                  <span className="flag-icon flag-icon-gb"></span>
                ) : (
                  <span className="flag-icon flag-icon-fr"></span>
                )}
                {/* <FcGlobe size={28} /> */}
              </div>
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    i18next.changeLanguage("en");
                    dispatch(set_LANGUAGE("en"));
                  }}
                >
                  <span className="flag-icon flag-icon-gb mx-2"></span>
                  English
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    i18next.changeLanguage("fr");
                    dispatch(set_LANGUAGE("fr"));
                  }}
                >
                  <span className="flag-icon flag-icon-fr mx-2"></span>
                  Francais
                </a>
              </li>
            </ul>
          </li>
          <li
            className="nav-item dropdown"
            style={{
              display: "inline-block",
              padding: "0 0.8rem",
              lineHeight: "32px",
            }}
          >
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i
                className="bx bx-message-dots"
                style={{ verticalAlign: "middle", fontSize: "20px" }}
              />
              {/* <span className="position-absolute h-badge translate-middle badge rounded-pill bg-danger">
                3
              </span> */}
            </a>
          </li>

          <li
            className="nav-item dropdown"
            style={{
              display: "inline-block",
              padding: "0 0.8rem",
              lineHeight: "32px",
            }}
          >
          <NotificationDrawer notifications={notifications} t={t}/>
          </li>

          <li
            className="nav-item dropdown"
            style={{ display: "inline-block", padding: "0 0.8rem" }}
          >
            <a
              className="nav-link "
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="avatar avatar-md">
                <img className="avatar-img" src={user} alt="user@email.com" />
                {/* <span class="avatar-status bg-success"></span> */}
              </div>
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  className="dropdown-item"
                  style={{ cursor: "none" }}
                  href="#"
                >
                  {globalAuthUser?.location?.name}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink className="dropdown-item" to="/user-profile">
                  {globalAuthUser?.first_name + " " + globalAuthUser?.last_name}
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {/* <li><a className="dropdown-item" href="#">Profile</a></li> */}
              <li>
                <a
                  className="dropdown-item"
                  onClick={onSignOut}
                  id="logoutBtn"
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="bx bx-log-out"
                    style={{ fontSize: "18px", paddingRight: "8px" }}
                  />
                  {t("LOGOUT")}
                </a>
              </li>
              {/* <li><hr className="dropdown-divider" /></li> */}
              {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
            </ul>
          </li>
          {/* <li className="nav-item dropdown" style={{ display: "inline-block", padding: "0 0.8rem" }}>
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li> */}

          {/* <li style={{ display: "inline-block",  padding: "0 0.5rem" }}>Item1</li>
                    <li style={{ display: "inline-block", padding: "0 0.5rem" }}>Item2</li>
                    <li style={{ display: "inline-block", padding: "0 0.5rem" }}>Item3</li> */}
        </ul>

        {/* <ul className="me-auto mb-2 mb-lg-0">
                    <li className="">
                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled">Disabled</a>
                    </li>
                </ul> */}
      </div>
    </>
  );
}

export default Header;
