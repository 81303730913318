import axios from "axios";
import { useAuthUser } from "react-auth-kit";

export const useHttpClient = () => {
  const authUser = useAuthUser();
  const token = authUser()?.token;
  const tokenType = authUser()?.tokenType;

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BNB_BASE_URL + "/api/portal",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `${tokenType} ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};
